.cfra-ag-grid {
    .ag-theme-alpine.grid-theme-v4 {
        
        // header
        --ag-header-foreground-color: #5A5A5A;

        --ag-borders: none;
        /* then add back a border between rows */
        --ag-row-border-style: solid;
        --ag-row-border-width: 1px;
        --ag-row-border-color: #EEEEEE;
        --ag-cell-horizontal-border: 1px solid #EEEEEE;

        .ag-header-cell {
            font-size: 13.5px;
            font-weight: 500;
            line-height: 20px;
        }

        .ag-header {
            border-top: none;
            border-bottom: 1px solid #CCCCCC;
 
            .ag-header-cell.highlight-header {
                color: #007AB9;
            }
        }

        // rows
        .ag-row {
            font-weight: 400;
            color: #5A5A5A;
            font-size: 13.5px;

            .bold-value {
                font-family: 'GraphikMedium';
            }
            .ag-cell {
               border: 1px solid transparent;
            }
            .document-icon {
                padding: 10px;
            }
            .ag-cell-focus {
                border: 1px solid rgb(33, 150, 243);
            }
            
        }

        .ag-root ::-webkit-scrollbar {
            width: 15px;
        }
    
        .ag-root ::-webkit-scrollbar-button {
            height: 0px;
        }

        .ag-root ::-webkit-scrollbar-thumb {
            min-height: 51%;
            background-color: #E4E8F4;
            border-radius: 30px;
        }
    }
}
