$primary-color: #002B5A;
$basic-font: 'GraphikRegular';

$checkbox-size: 19px;
$checkbox-border-radius: 3px;
$checkbox-border-color: #3078B5;
$checkbox-bg-color: #fff;
$checkbox-checked-bg-color: #3078B5;
$checkbox-checkmark-bg-color: #fff;
$checkbox-color: #555;
$forgot-password-color: #007BB8;
$forgot-password-font-size: 14px;

$input-height: 60px;
$input-border-color: #ddd;
$input-border-radius: 10px;
$input-text-color: #555;
$input-font-size: 17px;
$input-focus-border-color: #ddd;
$input-focus-shadow: 0 0 4px 1px #1fb0f8 inset;

$button-background-color: $primary-color;
$button-text-color: #fff;
$button-font-family: 'GraphikBlack';
$button-font-size: 15px;
$button-height: 49px;
$button-text-transform: uppercase;
$email-password-margin: 14px;
$password-button-margin: 22px;
$input-max-width: 400px;

$h3-font-family: 'GraphikMedium';
$h3-font-size: 18px;

[data-amplify-authenticator] {
    --amplify-components-authenticator-router-box-shadow: none;
    --amplify-components-authenticator-router-border-width: 0;
    --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
    --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
    --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
    --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
    --amplify-components-button-link-color: var(--amplify-colors-purple-80);
    @media (max-width: 999.5px) {
        --amplify-components-button-link-color: #fff;
        }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    font-family: $basic-font;
}

[data-amplify-authenticator] .amplify-label,
[data-amplify-authenticator] .amplify-field__show-password {
    display: none;
}

[data-amplify-authenticator] [data-amplify-form] {
    padding: 10px 0 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 19px;
}

.checkbox-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: $forgot-password-font-size;
    color: $checkbox-color;

    .checkbox-input {
        width: $checkbox-size;
        height: $checkbox-size;
        border-radius: $checkbox-border-radius;
        border: 1px solid $checkbox-border-color;
        background-color: $checkbox-bg-color;
        display: inline-block;
        position: relative;
        transition: background-color 0.2s;
    }
}

.forgot-password-link, .cancel-link {
    padding: 0;
    min-width: auto;
    font-size: $forgot-password-font-size;
    color: $forgot-password-color;
    text-decoration: none;
    background: none;
    border: none;

    &:hover {
        text-decoration: underline;
    }
    &:focus {
        box-shadow: none;
    }
}

.cancel-link {
    color: #555;
}

[data-amplify-authenticator] .amplify-input {
    height: $input-height;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    color: $input-text-color;
    font-size: $input-font-size;
    font-family: $basic-font;
    border-end-end-radius: $input-border-radius !important;
    border-start-end-radius: $input-border-radius !important;
    min-width: $input-max-width;
    box-sizing: border-box;
    @media (max-width: 999.5px) {
        background: #fff;
    }
    @media (max-width: 403px) {
        min-width: 300px;    
    }
    
    
    &::placeholder {
        color: $input-text-color;
    }

    &:focus {
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-shadow;
        outline: none;
    }
}

[data-amplify-authenticator] .amplify-input-email {
    margin-bottom: $email-password-margin;
}

[data-amplify-authenticator] .amplify-input-password {
    margin-bottom: $password-button-margin;
}

[data-amplify-authenticator] .amplify-alert--error {
    border-radius: $input-border-radius;
}

[data-amplify-authenticator] .amplify-heading--3 {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    color: $primary-color;
    @media (max-width: 999.5px) {
        color: #fff;
    }
}

[data-amplify-authenticator] [data-amplify-form] .amplify-button.amplify-button--fullwidth,
[data-amplify-authenticator] [data-amplify-form] .amplify-button.amplify-button--primary {
    background-color: $button-background-color;
    color: $button-text-color;
    height: $button-height;
    font-family: $button-font-family;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
    border-radius: 10px;
    max-width: $input-max-width;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 999.5px) {
        border: 2px solid #ddd;
        background-color: transparent;
        }

    
    &:hover {
        background-color: darken($button-background-color, 10%);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 4px 1px #1fb0f8;
    }
}

[data-amplify-authenticator] [data-amplify-form] fieldset {
    gap: 14px !important;
}

[data-amplify-router-content] {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 999.5px) {
    [data-amplify-authenticator] [data-amplify-router] {
        background-color: transparent;
    }
}